/* Icons from: https://devicon.dev */
const DEV_TECH_MAP: Map<string, string> = new Map([
    ['amazon web service', 'devicon-amazonwebservices-plain'],
    ['android', 'devicon-android-plain'],
    ['angular', 'devicon-angularjs-plain'],
    ['apache', 'devicon-apache-plain'],
    ['aws', 'devicon-amazonwebservices-plain'],
    ['bash', 'devicon-bash-plain'],
    ['bootstrap', 'devicon-bootstrap-plain'],
    ['c#', 'devicon-csharp-plain'],
    ['c', 'devicon-c-plain'],
    ['c++', 'devicon-cplusplus-plain'],
    ['composer', 'devicon-composer-line'],
    ['css 3', 'devicon-css3-plain'],
    ['css', 'devicon-css3-plain'],
    ['css3', 'devicon-css3-plain'],
    ['docker', 'devicon-docker-plain'],
    ['flutter', 'devicon-flutter-plain'],
    ['gcp', 'devicon-google-plain'],
    ['git', 'devicon-git-plain'],
    ['google cloud', 'devicon-google-plain'],
    ['google', 'devicon-google-plain'],
    ['html 5', 'devicon-html5-plain'],
    ['html', 'devicon-html5-plain'],
    ['html5', 'devicon-html5-plain'],
    ['java', 'devicon-java-plain'],
    ['javascript', 'devicon-javascript-plain'],
    ['jest', 'devicon-spring-plain'],
    ['latex', 'devicon-latex-original'],
    ['markdown', 'devicon-markdown-original'],
    ['matlab', 'devicon-matlab-plain'],
    ['mocha', 'devicon-mocha-plain'],
    ['mongodb', 'devicon-mongodb-plain'],
    ['my sql', 'devicon-mysql-plain'],
    ['mysql', 'devicon-mysql-plain'],
    ['next', 'devicon-nestjs-plain'],
    ['next.js', 'devicon-nestjs-plain'],
    ['node', 'devicon-nodejs-plain'],
    ['node.js', 'devicon-nodejs-plain'],
    ['php', 'devicon-php-plain'],
    ['python', 'devicon-python-plain'],
    ['react', 'devicon-react-plain'],
    ['sass', 'devicon-sass-original'],
    ['spring boot', 'devicon-spring-plain'],
    ['spring', 'devicon-jest-plain'],
    ['sql', 'devicon-mysql-plain'],
    ['tensorflow', 'devicon-tensorflow-original'],
    ['typescript', 'devicon-typescript-plain'],
    ['unity', 'devicon-unity-original'],
    ['vue', 'devicon-vuejs-plain'],
    ['vue.js', 'devicon-vuejs-plain'],
    ['npm', 'devicon-npm-original-wordmark'],
    ['perl', 'devicon-perl-plain'],
    ['postgres', 'devicon-postgresql-plain'],
    ['post gres', 'devicon-postgresql-plain'],
    ['postgressql', 'devicon-postgresql-plain'],
    ['postgres sql', 'devicon-postgresql-plain'],
    ['post gres sql', 'devicon-postgresql-plain'],
    ['r', 'devicon-r-original'],
    ['ruby', 'devicon-ruby-plain'],
    ['ruby in rails', 'devicon-rails-plain'],
    ['rails', 'devicon-rails-plain'],
    ['rust', 'devicon-rust-plain'],
    ['sass', 'devicon-sass-original'],
    ['swift', 'devicon-swift-plain']
]);

export function createIcon(tech: string): string {
    return DEV_TECH_MAP.get(tech.toLowerCase()) ?? '';
}
